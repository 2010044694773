<template>
	<div>
		<!-- <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> -->

		<v-container>
			<v-tabs v-model="currentItem">
				<v-tab href="#LayetteAssemble"> Armar Canastilla</v-tab>
				<v-tab href="#LayetteFormed"> Canastillas Procesadas</v-tab>
				<v-tab href="#StrecherAvailable"> Parihuelas Disponibles</v-tab>
			</v-tabs>

			<v-tabs-items v-model="currentItem">
				<v-tab-item :value="'LayetteAssemble'"> 
                    <create-layette />
                </v-tab-item>

				<v-tab-item :value="'LayetteFormed'"> 
                    <create-layette-formed />
                </v-tab-item>

				<v-tab-item :value="'StrecherAvailable'"> 
                    <strecher-available />
                </v-tab-item>
			</v-tabs-items>
		</v-container>
	</div>
</template>
<script>
	//Component
	import CreateLayette from "./CreateLayette.vue";
    import CreateLayetteFormed from "./CreateLayetteFormed.vue";
    import StrecherAvailable from "./StrecherAvailable.vue";

	export default {
		components: { CreateLayette, CreateLayetteFormed, StrecherAvailable},
		data: () => ({
			currentItem: "tab-Funciones",
		}),
	};
</script>
