<template style="display: inline-table;">
	<!-- <v-row> -->
	<v-card>
		<v-col cols="12">
				<v-toolbar dense>
					<v-toolbar-title
						>Detalle de Parihuelas
						<v-chip style="margin: 0px" small :color="'info'"> </v-chip>
						<v-btn
							class="mx-4"
							fab
							dark
							color="error"
							small
                            @click="close()"
						>
							<v-icon dark>
								mdi-close
							</v-icon>
						</v-btn>
					</v-toolbar-title>
				</v-toolbar>
				<v-card dense>
					<v-card-title>
					</v-card-title>
					<v-data-table
						item-key=""
						dense
						:items-per-page="-1"
						:items="details"
						:headers="headers"
                        hide-default-footer
						disable-sort
					>
					</v-data-table>
				</v-card>
			</v-col>

	</v-card>

	
	<!-- </v-row> -->
</template>
<script>

	export default {
		props: {
			items: null,
		},
		data: () => ({
            headers: [
				{ text: "Parihuela", value: "LlpID"},
                { text: "Lote Produccion", value: "RcfID"},
                { text: "Cant. Jabas", value: "LppQuantityJaba"},
                { text: "Productor", value: "PrdCardName"},
                { text: "Fundo", value: "FagName"},
                { text: "Lote", value: "FltName"},
                { text: "Tipo Cultivo", value: "TypeCropName"},
                { text: "Cultivo", value: "TypeCultiveName"},
                { text: "Variedad", value: "VrtName"},
                { text: "Guia Cosecha", value: "RcfNumberHarvestGuide"},
                { text: "Cod. Senasa", value: "FltCodSenasa"},
				{ text: "Posición", value: "LppPosition"},
            ],

            details: [],
		}),

        mounted () {
            
            this.items.forEach(element => {
                this.details = element.details
            });
        },

        methods: {
            close(){
                this.$emit("close")
            }
        }   




		
	};
</script>
