<template>
	<v-card class="m-0 p-0">
		<v-col cols="12" lg="12" md="12" style="display: contents;">
			<template style="margin-top: 10px;">
				<s-crud
					
					:filter="filter"
					:config="config"				
					addNoFunction
					height="auto"
					no-full
					search-input
					title="Parihuelas Disponibles"
				>
					<template v-slot:filter>
						<v-container>
							<v-row style="margin-left: 5px" justify="center">
								<v-col cols="6" md="3" lg="3">
									<s-date
										label="Fecha Inicio"
										v-model="filter.cDateInitial"
									></s-date>
								</v-col>
								<v-col cols="6" md="3" lg="3">
									<s-date
										label="Fecha Fin"
										v-model="filter.cDateFin"
									></s-date>
								</v-col>
							</v-row>
						</v-container>
					</template>

					<template v-slot:RcfID={row}>
						<v-chip
							small
							color="success"
						>
							{{row.RcfID}}
						</v-chip>
					</template>

					<template v-slot:RcfNumberHarvestGuide={row}>
						<v-chip
							small
							outlined
							color="info"
						>
							{{row.RcfNumberHarvestGuide}}
						</v-chip>
					</template>

					<template v-slot:xCount={row}>
						<v-chip
							x-small
							:color="row.xCount >= 1 ? 'info' : 'error'"
						>
							{{row.xCount >= 1 ? 'Abastecido' : 'Sin Abast.'}}
						</v-chip>
					</template>

					<template v-slot:Process={row}>
						<v-chip
						 x-small
						 :color="row.Process == 1 ? 'success' : 'warning'">
							{{row.Process == 1 ? 'Reproceso':'Normal' }}
						</v-chip>
					</template>
				</s-crud>
			</template>
		</v-col>
	</v-card>
</template>

<script>
	import _sLotProdCalibratedPrintQRService from "../../../services/FreshProduction/PrfLotProductionCalibratedQRService";

	export default {
		props: {},
		
		data: () => ({
			filter: {
				TypeDestiny: 2,
				cDateInitial: null,
				cDateFin: null,
				Process: null,
				Hidro : 1,
			},
			config: {
				service: _sLotProdCalibratedPrintQRService,
				model: {
					LlpID: "ID",
					RcfID: "",
					RcfNumberHarvestGuide: "",
					xCount:"",
					Process: "",
				},
				headers: [
					{ text: "Codigo", value: "LlpName", width: 100 },
					{ text: "Lote Producción", value: "RcfID", width: 150 },
					{ text: "Guia Cosecha", value: "RcfNumberHarvestGuide", width: 200 },
					{ text: "Cant. Jaba", value: "LlpQuantityJaba", width: 100 },
					{ text: "Calibre", value: "TypeCaliberName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Estado", value: "xCount", width: 100},
					{ text: "Tipo Pallet", value:"Process", width: 100},
				],
			},
			selected: [],
			openDialog: true,
			itemsDetail : [],
			WbbNetWeight: 0,
		}),

		methods: {
			click(val) {
				this.filter.TypeDestiny = val.DedValue;
				this.openDialog = false;
			},
		},
	};
</script>

<style></style>
