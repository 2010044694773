<template>
	<div>
		<!-- <s-select-definition hidden @change="changeLayette($event)" label="Nro Canastilla" :def="1259"  /> -->

		<v-container>
			<v-card flat>
				<v-row justify="center">
					<div class="col-lg-3 col-md-4 col-sm-4">
						<b>
							<v-text-field
								v-model="search"
								append-icon="mdi-magnify"
								label="Buscar"
								single-line
								hide-details
							></v-text-field>
						</b>
					</div>

					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha Inicio"
							v-model="BeginDate"
							@input="inputFilterBegin($event)"
						></s-date>
					</v-col>
					<v-col cols="6" lg="3" md="3">
						<s-date
							label="Fecha Fin"
							v-model="EndDate"
							@input="inputFilterEnd($event)"
						></s-date>
					</v-col>
				</v-row>
			</v-card>
			<v-card dense style="margin-top: 20px">
				<v-row>
					<v-col cols="12">
						<v-data-table
							item-key="LytID"
							dense
							:headers="headers"
							:items="items"
							:search="search"
						>
							<template v-slot:item.Options="{ item }">
								<v-tooltip right>
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											x-small
											rounded
											fab
											color="warning"
											v-bind="attrs"
											v-on="on"
											@click="clcikRework(item)"
										>
											<i class="fas fa-fire-alt"></i>
										</v-btn>
									</template>
									<span>Re-Proceso</span>
								</v-tooltip>
							</template>

							<template v-slot:item.RcfID="row">
								<v-chip small dark outlined :color="'info'">
									{{ row.item.RcfID }}
								</v-chip>
							</template>

							<template v-slot:item.TnkDifferenceEstimate="row">
								<v-chip small dark outlined :color="'red'">
									{{ row.item.TnkDifferenceEstimate }}
								</v-chip>
							</template>

							<template v-slot:item.TnkDifferenceReal="row">
								<v-chip small dark :color="'info'">
									{{ row.item.TnkDifferenceReal }}
								</v-chip>
							</template>
						</v-data-table>
					</v-col>
				</v-row>
			</v-card>
		</v-container>
	</div>
</template>

<script>
	import _sLayetteService from "../../../services/FreshProduction/PrfLayetteService";
	export default {
		components: {},
		data() {
			return {
				headers: [
					{ text: "Re-Proceso", value: "Options" },
					{ text: "Canas. Code ", value: "LytID", width: 100  },
					{ text: "Canastilla", value: "TypeLayetteName", width: 100 },
					{ text: "Cant. Jabas", value: "LppQuantityJaba", width: 80, align: "center"},
					{ text: "Lote Producción", value: "RcfID", width: 300 },
					{ text: "Frente", value: "TypeFrontLayette", width: 100 },
					{ text: "Tiempo", value: "TypeTimeHidro", width: 100 },
					{ text: "Tipo Cultivo", value: "TypeCropName", width: 100 },
					{ text: "Cultivo", value: "TypeCultiveName", width: 100 },
					{ text: "Variedad", value: "VrtName", width: 100 },
					{ text: "Tanque", value: "TypeTankName", width: 100 },
					{
						text: "Linea Proceso",
						value: "TypeLineProcessName",
						width: 100,
					},
					{
						text: "Numero Tratamiento",
						value: "TnkNumberTreatment",
						width: 100,
					},
					{ text: "Posicion", value: "TnkPosition", width: 100 },
					{ text: "Hora Inicio", value: "TnkHourEntry", width: 150 },
					{ text: "Hora Fin", value: "TnkHourExit", width: 150 },
					{
						text: "Tiempo Real estimado (Min)",
						value: "TnkDifferenceEstimate",
						width: 100,
					},
					{
						text: "Tiempo Real (Min)",
						value: "TnkDifferenceReal",
						width: 100,
					},
				],
				items: [],
				BeginDate: null,
				EndDate: null,
				search: "",
			};
		},

		created() {
			//this.initialize()
		},

		methods: {
			initialize() {
				_sLayetteService
					.GetLayetteFormed(
						{ BeginDate: this.BeginDate, EndDate: this.EndDate },
						this.$fun.getUserID(),
					)
					.then(data => {
						if (data.status == 200) {
							this.items = data.data;

							this.items.forEach(element => {
								element.TnkHourEntry = this.$moment(
									element.TnkHourEntry,
								).format(this.$const.FormatDateTimeDB);
								element.TnkHourExit = this.$moment(
									element.TnkHourExit,
								).format(this.$const.FormatDateTimeDB);

								element.TnkDifferenceEstimate =
									this.$fun.isDifferenceMinutes(
										element.TnkHourEntry,
										element.TnkHourExit,
									) * -1;
								element.TnkDifferenceReal =
									this.$fun.isDifferenceMinutes(
										element.TnkHourEntry,
										element.TnkDateReal,
									) * -1;


									
							});
						}
					});
			},

			inputFilterBegin(val) {
				this.BeginDate = val;
				this.initialize();
			},

			inputFilterEnd(val) {
				this.EndDate = val;
				this.initialize();
			},

			clcikRework(item) {
				
				item.UsrCreateID = this.$fun.getUserID();
				this.$fun.alert("Seguro de enviar canastilla a REPROCESO ?", "question").then(r => {
					if(r.value){
						_sLayetteService.saveRework(item, this.$fun.getUserID()).then(data =>{
							if(data.status == 200){
								this.initialize()
								this.$fun.alert("Canastilla enviada a reproceso correctamente", "success")
							}
						})
					}
				})
				console.log(item);
			},
		},
	};
</script>
