<template>
	<div>
		<v-container>
			<v-row>
				<div class="col-12">
					<s-select-definition
						:def="1181"
						label="Destino" 
						clearable
						@getDataInitial="getDataTypeDestiny($event)"
						hidden
						/>
				</div>
				<div class="col-md-12 col-sm-12">
					<v-toolbar dense>
						<v-toolbar-title
							>Canastillas
							<v-chip style="margin: 0px" small :color="'info'">
							</v-chip>
						</v-toolbar-title>
						<v-btn
							x-small
							:color="'info'"
							fab
							style="margin: 10px 10px 10px 10px;"
							@click="initialize()"
						>
							<v-icon class="fas fa-sync-alt"></v-icon>
						</v-btn>
					</v-toolbar>
					<v-card dense style="margin-top: 10px">
						<v-row>
							<v-col
								style="margin: auto;"
								cols="4"
								lg="2"
								md="4"
								v-for="item in itemsLayette"
								:key="item.DedID"
							>
								<v-card height="130">
									<v-card-text class="justify-center">
										<v-btn
											v-if="item.LytID > 0"
											x-small
											elevation="0"
											fab
											dark
											color="error"
											style="margin-left: -25px;margin-top: -35px;"
											@click="clickAnulate(item)"
										>
											<v-icon style="font-size: 16px !important"
												>fas fa-times</v-icon
											>
										</v-btn>
										<b>Canastilla {{ item.DedDescription }}</b>
										<v-chip
											x-small
											:color="item.LytID > 0 ? 'error' : ''"
										>
											<b>{{
												item.LytID > 0 ? "Ocupado" : "Libre"
											}}</b> </v-chip
										><span>
											<v-chip
												x-small
												:color="
													item.TypeState == 2 ? 'warning' : ''
												"
											>
												<b>{{ item.TypeStateName }}</b>
											</v-chip></span
										>
									</v-card-text>
									<v-card-actions class="justify-center">
										<v-btn
											style="margin-top: -10px;"
											rounded
											:color="
												item.TypeState == 2
													? 'warning'
													: item.TypeState == 3
													? 'info'
													: 'success'
											"
											@click="clickNew(item)"
										>
											<v-icon v-if="item.TypeState == 2">
												fas fa-layer-group
											</v-icon>
											<v-icon v-else-if="item.TypeState == 3">
												fas fa-ban
											</v-icon>
											<v-icon v-else>
												fas fa-lock-open
											</v-icon>
										</v-btn>
									</v-card-actions>
								</v-card>
							</v-col>
						</v-row>
					</v-card>
				</div>
			</v-row>
		</v-container>

		<v-dialog
			v-if="openDialog"
			v-model="openDialog"
			hide-overlay
			transition="dialog-bottom-transition"
			persistent
			fullscreen
		>
		<!--  -->
			<create-layette-new
				:items="items"
				@close="close()"
				:TypeDestinyName="TypeDestinyName"
			></create-layette-new>
		</v-dialog>
	</div>
</template>

<script>
	import _sLayetteService from "../../../services/FreshProduction/PrfLayetteService";
	import CreateLayetteNew from "./CreateLayetteNew.vue";
	import sCreateLayette from "@/services/FreshProduction/PrfLayetteService";

	export default {
		components: { CreateLayetteNew },
		data() {
			return {
				itemsLayette: [],
				openDialog: false,
				items: {},
				listTypeDestiny: [],
				TypeDestinyName: "",
			};
		},

		created() {
			this.initialize();
		},

		methods: {
			getDataTypeDestiny(data){
				this.listTypeDestiny = data;
				
				this.listTypeDestiny.forEach(element => {
					var persm = this.$fun.hasSpecialPermission(element.DedDescription)
					if (persm) {
						this.TypeDestinyName = element.DedAbbreviation;
						console.log("listTypeDestiny element", element);
					}
					
				});
			},
			close() {
				this.openDialog = false;
				this.initialize();
			},

			initialize() {
				_sLayetteService.GetLayette(this.$fun.getUserID()).then(r => {
					if (r.status == 200) {
						this.itemsLayette = r.data;
						console.log(this.itemsLayette);
					}
				});
			},

			clickNew(item) {
				

				this.items = item;
				
				// if (this.TypeDestinyName == "") {
				// 	this.$fun.alert("No tienes permisos de visualización", "warning")
				// 	return;
				// }
				this.openDialog = true;

			},

			clickAnulate(item) {
				item.SecStatus = 0;
				item.UsrCreateID = this.$fun.getUserID();

				this.$fun
					.alert(
						"¿Seguro de liberar canastilla ==> " +
							item.DedDescription +
							" ?",
						"question",
					)
					.then(r => {
						if (r.value) {
							sCreateLayette
								.save(item, this.$fun.getUserID())
								.then(data => {
									if (data.status == 200) {
										this.$fun.alert(
											"Canastilla liberada correctamente",
											"success",
										);
										this.initialize();
									}
								});
						}
					});
			},
		},
	};
</script>
