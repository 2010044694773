<template>
	<div class="cc-card">
		<h3>
			PA - {{data.LlpID}}
			
		</h3>
		<p style="-webkit-text-stroke: thick; font-size: 12px">
			{{data.RcfID}}
		</p>
        <b>{{data.TypeCaliberName}} - {{data.LlpQuantityJaba}}</b>
        <br>
        <b>{{data.TypeCropName}} - {{data.TypeCultiveName}} - {{data.VrtName}}</b>
		<br>
        <b>{{data.LydPositionInitial}} - {{data.LydPositionFin}}</b>
		
	</div>
</template>

<script>
	export default {
		
		props: {data: null},
		methods: {
		},
	};
</script>
<style>
	.cc-card {
		padding: 10px;
		text-align: left;
		border: 1px solid #dbdbdb;
		border-radius: 5px;
		margin: 5px;
		background-color: white;
		min-width: 200px;
		font-size: 1em;
	}

	.cc-card h3 {
		margin: 0px;
	}
</style>